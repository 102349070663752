import { Command } from "cmdk";
import { useNavigate } from "react-router-dom";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { useAuthorizations } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";

export function BasePage({
  slug,
  onSelect,
}: {
  slug?: string;
  onSelect(): void;
}) {
  const navigate = useNavigate();

  const { features } = useAuthorizations([
    Feature.ViewJobs,
    Feature.CompanyProfile,
    Feature.Settings,
    Feature.UserProfile,
  ]);

  return (
    <Command.Group heading="Pages">
      {slug && features.get(Feature.ViewJobs)?.granted && (
        <Command.Item
          onSelect={() => {
            navigate(`${slug}/jobs`);
            onSelect();
          }}
        >
          <Icon icon="industry" size={1} />
          <Text>Jobs</Text>
        </Command.Item>
      )}
      {slug && features.get(Feature.CompanyProfile)?.granted && (
        <Command.Item
          onSelect={() => {
            navigate(`${slug}/company-profile`);
            onSelect();
          }}
        >
          <Icon icon="company" size={1} />
          <Text>Company</Text>
        </Command.Item>
      )}
      {slug && features.get(Feature.Settings)?.granted && (
        <Command.Item
          onSelect={() => {
            navigate(`${slug}/settings`);
            onSelect();
          }}
        >
          <Icon icon="settings" size={1} />
          <Text>Settings</Text>
        </Command.Item>
      )}
      {features.get(Feature.UserProfile)?.granted && (
        <Command.Item
          onSelect={() => {
            navigate(`user`);
            onSelect();
          }}
        >
          <Icon icon="circle-user" size={1} />
          <Text>You</Text>
        </Command.Item>
      )}
    </Command.Group>
  );
}
