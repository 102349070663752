import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconButton = styled.div`
  color: var(--icon-color);
  cursor: pointer;

  &:hover {
    color: var(--icon-hover-color);
  }

  &[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const DeleteButton = ({
  color = palette.brand.red,
  hoverColor = palette.extended.red.shade800,
  onClick,
  label = "Delete",
  disabled = false,
}: {
  hoverColor?: string;
  color?: string;
  onClick: () => void;
  label?: string;
  disabled?: boolean;
}) => {
  return (
    <IconButton
      role="button"
      style={
        {
          "--icon-color": color,
          "--icon-hover-color": hoverColor,
        } as React.CSSProperties
      }
      onClick={onClick}
      data-testid="delete-button"
      aria-label={label}
      aria-disabled={disabled}
    >
      <Icon icon="delete" size={2} />
    </IconButton>
  );
};
