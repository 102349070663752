import { CompanyOfficeInput } from "@hire/schema";

export const transformOut = (
  formValues: CompanyOfficeInput
): CompanyOfficeInput => {
  return {
    address: formValues.address,
    parsedAddress: formValues.parsedAddress,
    isHq: formValues.isHq,
  };
};
