import { useMutation, useQuery } from "@apollo/client";

import { OfficesForm } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CompanyOffice,
  CompanyOfficeInput,
  CompanyOfficesDocument,
  CreateCompanyOfficeDocument,
  DeleteCompanyOfficeDocument,
  UpdateCompanyOfficeDocument,
} from "@hire/schema";
import { Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

export const Offices = () => {
  const { data, loading, refetch } = useQuery(CompanyOfficesDocument);
  const [createCompanyOffice] = useMutation(CreateCompanyOfficeDocument, {
    onError: handleMutationError,
  });
  const [updateCompanyOffice] = useMutation(UpdateCompanyOfficeDocument, {
    onError: handleMutationError,
  });
  const [deleteCompanyOffice] = useMutation(DeleteCompanyOfficeDocument, {
    onError: handleMutationError,
  });

  if (loading) {
    return <Loading />;
  }

  const currentCompany = data?.currentUser?.currentCompany;
  if (!currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  const handleCreateCompanyOffice = async (input: CompanyOfficeInput) => {
    await createCompanyOffice({
      variables: {
        companyId: currentCompany.id,
        input,
      },
    });
  };

  const handleUpdateCompanyOffice = async (
    office: CompanyOffice,
    input: CompanyOfficeInput
  ) => {
    await updateCompanyOffice({
      variables: {
        officeId: office.id,
        input,
      },
    });
  };

  const handleDeleteCompanyOffice = async (office: CompanyOffice) => {
    await deleteCompanyOffice({
      variables: {
        officeId: office.id,
      },
    });
  };

  return (
    <>
      <Text size={2} bold>
        Offices
      </Text>
      <ResponsiveCenteredContainer>
        <OfficesForm
          data={currentCompany}
          onCreateCompanyOffice={handleCreateCompanyOffice}
          onUpdateCompanyOffice={handleUpdateCompanyOffice}
          onDeleteCompanyOffice={handleDeleteCompanyOffice}
        />
      </ResponsiveCenteredContainer>
    </>
  );
};
