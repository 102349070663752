import { useParams } from "react-router-dom";

import { MobileSubNavItem } from "./Mobile";

import { Icon } from "@otta/icons";

export function CompanyProfileSubNav({ onClick }: { onClick?: () => void }) {
  const { companySlug } = useParams<"companySlug">();

  return (
    <>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/details`}
        onClick={onClick}
      >
        Company details
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/offices`}
        onClick={onClick}
      >
        Offices
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/people`}
        onClick={onClick}
      >
        People
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/funding`}
        onClick={onClick}
      >
        Investors and funding
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/benefits`}
        onClick={onClick}
      >
        Employee benefits
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/links`}
        onClick={onClick}
      >
        External links
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/endorsements`}
        onClick={onClick}
      >
        Endorsements
      </MobileSubNavItem>
      <MobileSubNavItem
        to={`/${companySlug}/company-profile/visa-sponsorship`}
        onClick={onClick}
      >
        Visa sponsorship
      </MobileSubNavItem>
      <MobileSubNavItem
        as="a"
        href={`${
          import.meta.env.VITE_SEARCH_APP_HOST
        }/companies/${companySlug}`}
        target="_blank"
        onClick={onClick}
      >
        <div style={{ display: "flex", gap: 5 }}>
          <Icon icon="external" />
          <span>View profile</span>
        </div>
      </MobileSubNavItem>
    </>
  );
}
