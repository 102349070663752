import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import * as Yup from "yup";

import { Upsell } from "../CompanyDetails/Upsell";

import { transformOut } from "./transformers";

import {
  CompanyWithOfficesFragment,
  CompanyOffice,
  CompanyOfficeInput,
  Feature,
} from "@hire/schema";
import { Button, Card, InputField, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { BaseModal } from "@hire/components/modals/BaseModal";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";
import { EditButton } from "@hire/components/buttons/EditButton";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { useAuthorizations } from "@hire/providers/authorization";

export const OfficesForm = ({
  data,
  onCreateCompanyOffice,
  onUpdateCompanyOffice,
  onDeleteCompanyOffice,
}: {
  data: CompanyWithOfficesFragment;
  onCreateCompanyOffice: (input: CompanyOfficeInput) => void;
  onUpdateCompanyOffice: (
    office: CompanyOffice,
    input: CompanyOfficeInput
  ) => void;
  onDeleteCompanyOffice: (office: CompanyOffice) => void;
}) => {
  const [hq, ...otherOffices] = useMemo(() => {
    const offices = data.offices?.filter(
      (office): office is CompanyOffice => office !== null
    );

    const hq = offices?.find(office => office.isHq) as CompanyOffice;
    const otherOffices = offices?.filter(office => !office.isHq) || [];

    return [hq, ...otherOffices];
  }, [data.offices]);

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<CompanyOffice | null>(
    null
  );

  const { features } = useAuthorizations([Feature.Photos]);
  const showUpsell = !features.get(Feature.Photos)?.granted;

  const handleEdit = (office: CompanyOffice) => {
    setSelectedOffice(office);
    setModalVisible(true);
  };

  const handleClickAdd = () => {
    setSelectedOffice(null);
    setModalVisible(true);
  };

  return (
    <x.section
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="lg"
      textAlign="left"
    >
      <BaseModal
        open={modalVisible}
        onOpenChange={setModalVisible}
        heading={selectedOffice ? "Edit office" : "Add a new office"}
      >
        <Form
          office={selectedOffice}
          onCreate={onCreateCompanyOffice}
          onUpdate={onUpdateCompanyOffice}
          onClose={() => setModalVisible(false)}
        />
      </BaseModal>

      <x.div as={Card} display="flex" flexDirection="column" gap="xl" w="100%">
        <Text size={1} bold>
          Headquarters
        </Text>
        <Preview office={hq} onEdit={handleEdit} />
      </x.div>

      {otherOffices.length > 0 && (
        <x.div
          as={Card}
          display="flex"
          flexDirection="column"
          gap="xl"
          w="100%"
        >
          <Text size={1} bold>
            Other offices
          </Text>
          {otherOffices.map(office => (
            <Preview
              key={office.id}
              office={office}
              onEdit={handleEdit}
              onDelete={onDeleteCompanyOffice}
            />
          ))}
        </x.div>
      )}

      <x.button
        as={Button}
        level="secondary"
        display="flex"
        gap="sm"
        onClick={handleClickAdd}
      >
        <Icon icon="plus" size={2} />
        Add new office
      </x.button>

      {showUpsell && (
        <Upsell
          title="Showcase your offices with photos and videos"
          listItems={[
            "Link photos and office tour videos to each of your offices",
            "Show candidates what it’s like to work at your offices, and help them get a feel for your culture",
            "We’ll work with you to produce and curate the content candidates are after",
          ]}
        />
      )}
    </x.section>
  );
};

const validationSchema = Yup.object().shape({
  isHq: Yup.boolean(),
  address: Yup.string()
    .required("Address is required")
    .min(5, "Address must be at least 5 characters"),
  parsedAddress: Yup.string()
    .required("Display address is required")
    .min(5, "Address must be at least 5 characters"),
});

const Preview = ({
  office,
  onEdit,
  onDelete,
}: {
  office: CompanyOffice;
  onEdit: (office: CompanyOffice) => void;
  onDelete?: (office: CompanyOffice) => void;
}) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <x.div
      display="flex"
      w="100%"
      padding="xl"
      border="1px solid"
      borderColor="beige-400"
      borderRadius="md"
    >
      {deleteModalVisible && (
        <BaseModal
          open={deleteModalVisible}
          onOpenChange={setDeleteModalVisible}
          heading="Are you sure?"
        >
          <x.div display="flex" flexDirection="column" gap="lg" padding="lg">
            Do you really want to delete this? This action cannot be undone.
            <ButtonWrapper align="start">
              <Button
                level="secondary"
                type="button"
                onClick={() => setDeleteModalVisible(false)}
              >
                Cancel
              </Button>
              <Button
                level="destructive"
                type="submit"
                onClick={() => {
                  onDelete?.(office);
                  setDeleteModalVisible(false);
                }}
              >
                Delete
              </Button>
            </ButtonWrapper>
          </x.div>
        </BaseModal>
      )}
      <x.div flexGrow="1" display="flex" flexDirection="column" gap="lg">
        <x.div display="flex" flexDirection="column" gap="sm">
          <Text bold>Full address:</Text>
          <Text>{office.address}</Text>
        </x.div>

        <x.div display="flex" flexDirection="column" gap="sm">
          <Text bold>Display address:</Text>
          <Text>{office.parsedAddress}</Text>
        </x.div>
      </x.div>
      <x.div display="flex" gap="lg" alignItems="center">
        <EditButton onClick={() => onEdit(office)} />
        {onDelete && (
          <DeleteButton onClick={() => setDeleteModalVisible(true)} />
        )}
      </x.div>
    </x.div>
  );
};

const Form = ({
  office,
  onCreate,
  onUpdate,
  onClose,
}: {
  office: CompanyOffice | null;
  onCreate: (input: CompanyOfficeInput) => void;
  onUpdate: (office: CompanyOffice, input: CompanyOfficeInput) => void;
  onClose: () => void;
}) => {
  const initialValues = office || {
    address: "",
    parsedAddress: "",
    isHq: false,
  };

  const onSubmit = async (values: CompanyOfficeInput) => {
    if (office) {
      await onUpdate(office, transformOut(values));
    } else {
      await onCreate(values);
    }
    onClose();
  };

  const form = useFormik<CompanyOfficeInput>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <x.form
      onSubmit={form.handleSubmit}
      display="flex"
      flexDirection="column"
      gap="lg"
      padding="lg"
    >
      <InputField
        name="address"
        label="Full address"
        placeholder="12 Office street, London, N12 3LB, UK"
        onChange={form.handleChange}
        value={form.values.address ?? ""}
        error={form.touched.address ? form.errors.address : undefined}
      />
      <InputField
        name="parsedAddress"
        label="Displayed address"
        placeholder="Office street, London"
        onChange={form.handleChange}
        value={form.values.parsedAddress ?? ""}
        error={
          form.touched.parsedAddress ? form.errors.parsedAddress : undefined
        }
      />

      <ButtonWrapper align="start">
        <Button
          level="secondary"
          type="button"
          disabled={form.isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button level="primary" type="submit" disabled={form.isSubmitting}>
          Confirm
        </Button>
      </ButtonWrapper>
    </x.form>
  );
};
